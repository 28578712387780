/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';
import styled from 'styled-components';

// components
import AccordionSection from './accordion-section';

// styled components
import AccordionStyles from './styles/accordion-styles';

// images
import Elevanta from '../images/elevanta.png';
import SIB from '../images/sib-sqaure.jpg';
import USI from '../images/usi.jpg';
import XLT from '../images/xlt.png';
import EmcentrixLogo from '../images/EmCentrixLogo.png';
import irhLogo from '../images/irh-Logo.jpg';
import LeasecakeLogo from '../images/LeasecakeLogo.png';
import UpShowLogo from '../images/upshow-logo.png';
import TitaniumLogo from '../images/Titanium-logo.jpg';
import PepsiLogo from '../images/Pepsi-logo.jpg';
import ReachifyLogo from '../images/reachify-logo.png';
import PrepwizardLogo from '../images/prepwizard_logo.jpg';
import paylocityLogo from '../images/paylocity-logo.png';
import boaLogo from '../images/bank-of-america-logo.png';
import OnarollLogo from '../images/onaroll_logo.jpeg';
import GuidelineLogo from '../images/guideline-logo.png';
import IntrepidLogo from '../images/intrepid_logo.png';
import BudderflyLogo from '../images/budderfly_logo.png';
import FranzBizLogo from '../images/franbiz_logo.png';
import Restaurant365 from '../images/restaurant_365_logo.png';
import rockbotLogo from '../images/rockbot-logo.png';
import proliantLogo from '../images/proliant-logo.jpg';
import TraneLogo from '../images/trane-logo.png';
import FinnPrice from '../images/finn-price-logo.png';
import QueenvestLogo from '../images/queenvest-logo.jpg';
import PizzaAgentsLogo from '../images/pizza-agents-logo.png';
import JPMPartsLogo from '../images/jpm-parts-logo.png';

const PersonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: ${props => props.theme.black};
    padding-right: 90px;

    p,
    img {
        margin: 0;
    }

    .imagePlaceholder {
        /* width: rem(100px); */
        /* height: 200px; */
    }

    .name {
        font-weight: bold;
        display: flex;
    }

    @media (max-width: 768px) {
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
        padding-right: 0;
        flex-direction: column;

        .personDetails {
            padding-left: 0;
            padding-top: 10px;
        }

        .print {
            width: 100%;
            height: 200px;
        }
    }
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 225px;
    max-width: 225px;
    word-break: break-word;

    @media (max-width: 768px) {
        padding-top: 10px;
    }
`;

const SectionContainer = styled.div`
    padding: 35px 0;
    @media (max-width: 768px) {
        display: flex;
        align-items: flex-start;
    }
`;

const ImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        max-width: none;
        width: 100%;
        padding-bottom: 16px;
    }
    max-width: 250px;
    width: 100%;
`;

const Img = styled.img`
    align-self: flex-start;
    object-fit: contain;
    height: auto !important;
    width: 100%;

    max-height: 150px !important;
`;

const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    padding-left: 40px;
    width: 400px;

    @media (max-width: 768px) {
        padding-left: 0;
        padding-top: 10px;
        width: 100%;
    }
`;

const VendorDirectoryAccordion = () => (
    <AccordionStyles>
        {/* Healthcare */}
        <AccordionSection title="Healthcare">
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={Elevanta}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Elevanta Health</span>
                        <span className="address">
                            Blue Cross Blue Shield health plans.
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Lauren Johnson
                        <br />
                        <a href="mailto:laurenj@elevanta.com">
                            laurenj@elevanta.com
                        </a>
                    </p>
                </ContactContainer>
            </SectionContainer>
        </AccordionSection>
        {/* Business Services */}
        <AccordionSection title="Business Services">
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={FranzBizLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">FranBizNetwork</span>
                        <span className="address">
                            FranBizNetwork is a Nationwide Franchise M&A. We
                            have experience in both multi-unit
                            operation/ownership and Franchise M&A. We bring real
                            business experience and professional integrity to
                            the table, which translates into more successful
                            transactions for our clients. FranBizNetwork is no
                            cookie-cutter brokerage firm; we understand that
                            each transaction is unique and are able to come up
                            with solutions that work. Bringing Buyers and
                            Sellers together is our passion, and we strive to be
                            a valuable resource to the franchise community.
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Matteya Raffelson
                        <br />
                        Marketing & Transaction Manager
                        <br />
                        <a href="mailto:Matteya@franbiznetwork.com">
                            Matteya@franbiznetwork.com
                        </a>
                        <br />
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://Franbiznetwork.com"
                        >
                            Franbiznetwork.com
                        </a>
                        <br />
                        <a href="tel:925-338-0188">925-338-0188</a>
                    </p>
                </ContactContainer>
            </SectionContainer>

            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={BudderflyLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Budderfly</span>
                        <div className="address">
                            <text>
                                Budderfly, ranked as one of the fastest-growing
                                Energy as a Service (EaaS) companies in the
                                United States, is the premier sustainability
                                partner for businesses with repeatable
                                footprints, such as restaurant chains, assisted
                                living facilities, retail franchises, and more.
                                Budderfly installs, monitors, and manages a
                                combination of patented technologies, equipment
                                upgrades, and proprietary energy software for
                                its customers at no out-of-pocket cost.
                                Businesses benefit from lower energy bills, a
                                reduced carbon footprint, more reliable
                                operations, and an improved customer and
                                employee experience. Budderfly has ranked on the
                                Inc. 5000 America's Fastest-Growing Private
                                Companies list from 2021 to 2023.
                            </text>
                        </div>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer></ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={TitaniumLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Titanium</span>
                        <div className="address">
                            <text>
                                Reduce your credit card fees through Titanium's
                                cash discount pricing. Current LC franchisees
                                seeing up to 2% points added to their bottom
                                lines.
                            </text>
                        </div>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Allan Sloat
                        <br />
                        <text>(847) 612- 1326</text>
                        <br />
                        <a href="mailto:asloatlc@aol.com">asloatlc@aol.com</a>
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={LeasecakeLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Leasecake</span>
                        <span className="address">
                            Leasecake is the operating system for location
                            management. As the sole source of truth for all
                            lease and location information, Leasecake helps
                            business owners stay on top of time-sensitive,
                            mission-critical events. With remote access and team
                            sharing, multi-location operators save time,
                            minimize risk, and better manage their growth.
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Taj Adhav
                        <br />
                        407-704-9518
                        <br />
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://leasecake.com"
                        >
                            leasecake.com
                        </a>
                        <br />
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://meetings.hubspot.com/taj1"
                        >
                            Click here to schedule time with me
                        </a>
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={SIB}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">SIB</span>
                        <span className="address">
                            Fixed Cost Reduction Services
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Christian Johnson
                        <br />
                        <a href="mailto:christian@aboutsib.com">
                            christian@aboutsib.com
                        </a>
                    </p>
                </ContactContainer>
            </SectionContainer>
        </AccordionSection>
        {/* Equipment */}
        <AccordionSection title="Equipment & Supplies">
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={JPMPartsLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">JPM Parts</span>
                        <span className="address">
                            JPM Parts is a trusted leader in the restaurant
                            equipment industry, specializing in replacement
                            parts and full-service equipment repair. With a
                            commitment to excellence and a passion for customer
                            satisfaction, they have established themselves as a
                            reliable partner for restaurants, commercial
                            kitchens, and foodservice establishments across the
                            globe.
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        JPM Parts
                        <br />
                        1435 Ellerd Dr.
                        <br />
                        Turlock, CA 95380
                        <br />
                        <a href="tel:800-651-9271">800-651-9271</a>
                        <br />
                        <a href="mailto:info@jpmparts.com">info@jpmparts.com</a>
                    </p>
                </ContactContainer>
            </SectionContainer>

            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={TraneLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Trane</span>
                        <span className="address">
                            Trane is the #1 HVAC Manufacturer in the World.
                            <br />
                            <br />
                            We specialize RTUs and Split Systems for accounts
                            like Little Caesars and strive to deliver the
                            highest level of customer service.
                            <br />
                            <br />
                            Trane is excited about the partnership and we look
                            forward to a long relationship.
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Marty Cusick
                        <br />
                        Trane National Accounts
                        <br />
                        <a href="tel:866.986.4822">Office: 866.986.4822</a>
                        <br />
                        <a href="tel:502.558.4879">Cell: 502.558.4879</a>
                        <br />
                        <a
                            href="https://www.trane.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            www.trane.com
                        </a>
                    </p>
                </ContactContainer>
            </SectionContainer>

            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={PrepwizardLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">PrepWizard</span>
                        <span className="address">
                            PrepWizard is an automated expiration labeling
                            solution that replaces the handwriting process of
                            use-by labels. In one minute, PrepWizard will
                            automatically calculate and print use-by labels for
                            your restaurant prep. The PrepWizard app works on
                            Apple or Android devices, and we preload your
                            products so there is no setup required. Now offering
                            14 Day Risk Free trials to all Little Caesars
                            Franchisees!
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer />
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={PepsiLogo}
                        />
                    </ImgContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Lisa Small | Sr. National Account Sales Manager
                        <br />
                        PepsiCo Foodservice Direct
                        <br />
                        <a href="mailto:lisa.small@pepsico.com">
                            lisa.small@pepsico.com
                        </a>
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={XLT}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">XLT Ovens</span>
                        <span className="address">
                            The restaurant business is challenging enough
                            without having to worry about unreliable equipment
                            and not getting help when you need it, that's why
                            XLT manufactures the highest quality and most
                            reliable equipment available allowing you to cook
                            the best possible Pizza, reduce your cost and down
                            time, and keep customers coming back for more!
                        </span>
                        <br />
                        <span className="address">
                            We are ready to help 24/7/365
                        </span>
                        <br />
                        <span className="address">
                            <a
                                className="address"
                                href="http://www.xltovens.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Click here
                            </a>{' '}
                            to learn more about XLT
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        John Klingenberger
                        <br />
                        <a href="tel:(316)-943-2751">(316)-943-2751</a>
                    </p>
                </ContactContainer>
            </SectionContainer>
        </AccordionSection>
        {/* Finance */}
        <AccordionSection title="Finance">
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={QueenvestLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Queenvest</span>
                        <span className="address">
                            We teach franchise owners how to retire. If you're
                            worried about:
                            <br />
                            <br />
                            <ul
                                style={{
                                    marginLeft: '20px',
                                    listStyleType: 'disc',
                                }}
                            >
                                <li>Outliving your money in retirement</li>
                                <li>Overpaying taxes on your income</li>
                                <li>
                                    Missing out on thousands in Social Security
                                    benefits
                                </li>
                                <li>Choosing the wrong Medicare coverage</li>
                                <li>
                                    Paying too much in out-of-pocket health care
                                    costs
                                </li>
                                <li>
                                    Having too much time in your day without
                                    purpose
                                </li>
                                <li>
                                    Leaving too little wealth to your family
                                </li>
                            </ul>
                            <br />
                            Then{' '}
                            <a
                                href="http://www.queenvestllc.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                click here
                            </a>{' '}
                            to go to our website and learn how you can plan and
                            live a long, financially secure, and happy
                            retirement.
                            <br />
                            <br />
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Ruzanna Queenan, CFA
                        <br />
                        Your Longevity Guide
                        <br />
                        <a href="mailto:ruzanna@queenvestllc.com">
                            ruzanna@queenvestllc.com
                        </a>
                        <br />
                        <a href="tel:6179137346">(617) 913-7346</a>
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={FinnPrice}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Railroad Investment Group</span>
                        <span className="address">
                            Finn Price is a partner at Railroad Investment Group
                            (RIG), a comprehensive wealth planning firm. We
                            specialize in helping individuals and business
                            owners make informed decisions in key areas,
                            including tax planning, estate planning, risk
                            management, cash flow management, and business
                            planning. When it comes to tax & financial
                            strategies, finding clear and reliable answers isn't
                            as simple as searching online. The internet is
                            packed with information, but it's hard to tell
                            what's accurate and what truly applies to your
                            unique circumstances. At RIG, we cut through the
                            noise to provide personalized, actionable advice
                            tailored to your goals.
                            <br />
                            <br />
                            <a
                                href="https://www.finnprice.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Click Here to Learn More
                            </a>
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Finn Price
                        <br />
                        Wealth Manager | Business Owner
                        <br />
                        <a href="tel:3347403677">(c) - 334-740-3677</a>
                        <br />
                        <a href="mailto:finn@rigopelika.com">
                            (e) - finn@rigopelika.com
                        </a>
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={GuidelineLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Guideline</span>
                        <span className="address">
                            The trusted 401(k) built for franchises. Simplify
                            retirement benefits as you scale with an affordable,
                            all-in-one 401(k).
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Candace Sake
                        <br />
                        Product Marketing Manager
                        <br />
                        1412 Chapin Avenue, Burlingame, CA 94010
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={boaLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Bank of America</span>
                        <span className="address">
                            Bank of America Franchise Solutions is the franchise
                            specific lending division of Bank of America Small
                            Business. Founded in 2013, Franchise Solutions has
                            quickly grown to become one of the leading financial
                            services providers to franchisees in the country.
                            Leveraging a franchise specific national sales
                            forces, Franchise Solutions provides customized
                            lending solutions to established franchise owners
                            looking to purchase, remodel, expand, relocate or
                            renovate a franchise, or to build/acquire commercial
                            real estate.
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Jason J. Baroni, CFE
                        <br />
                        Senior Vice President
                        <br />
                        Franchise Program Development Manager
                        <br />
                        Small Business – Franchise Solutions
                        <br />
                        Bank of America
                        <br />
                        <a href="tel:2488243307">M: 248.824.3307</a>
                        <br />
                        <a href="tel:3124533082">F: 312.453.3082</a>
                        <br />
                        <a href="mailto:jason.baroni@bofa.com">
                            jason.baroni@bofa.com
                        </a>
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={irhLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">IRH Capital</span>
                        <span className="address">
                            IRH Capital has been providing financing to the
                            Little Caesars community for nearly twenty years. We
                            pride ourselves on offering world class customer
                            service combined with aggressive financing terms.
                            IRH Capital provides financing for: new builds,
                            refinance of existing debt, acquisitions, remodels,
                            and equipment upgrades. We offer up to 100%
                            financing from $10,000 up to $10 million. For
                            additional information please visit our website
                            irhcapital.com. We can be reached at
                            info@irhcapital.com or 888-747-4227.
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Jesse Parker
                        <br />
                        Senior Account Executive
                        <br />
                        <a href="tel:(847)-374-9300">(847)-374-9300 x 316</a>
                    </p>
                </ContactContainer>
            </SectionContainer>
        </AccordionSection>
        {/* Human Resources */}
        <AccordionSection title="Human Resources">
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <a
                            href="https://www.proliant.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img
                                alt="Placeholder person"
                                className="imagePlaceholder"
                                src={proliantLogo}
                            />
                        </a>
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">ProLiant</span>
                        <span className="address">
                            ProLiant provides a fully integrated, cloud-based
                            human capital management solution that simplifies
                            payroll and HR processes, improves accuracy, and
                            ensures industry compliance. From day one,
                            ProLiant's dedicated account managers work closely
                            with clients to ensure they get the most out of
                            their investment. Our all-in-one solution simplifies
                            every aspect of workforce and talent management
                            across the entire employment life cycle. Everyone in
                            your organization—from the C-suite to managers and
                            employees—benefits from using ProLiant's easy to
                            use, innovative platform. Our people are dedicated
                            to helping your team manage their most critical
                            assets—your people. ProLiant delivers a perfect
                            balance of technology and services supported by
                            experienced industry experts. Over the years we have
                            invested time in developing innovative products that
                            integrate seamlessly with many widely used
                            restaurant software systems and we offer discounts
                            for IOLCF members.
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Ryan Bays
                        <br />
                        Sr. District Manager | Sales
                        <br />
                        <a href="mailto:rbays@proliant.com">
                            rbays@proliant.com
                        </a>
                        <br />
                        DIR: <a href="tel:336-214-2738">336-214-2738</a>
                        <br />
                        <a href="https://www.proliant.com">www.proliant.com</a>
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <a
                            href="https://www.paylocity.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img
                                alt="Placeholder person"
                                className="imagePlaceholder"
                                src={paylocityLogo}
                            />
                        </a>
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Paylocity</span>
                        <span className="address">
                            Headquartered in Schaumburg, IL, Paylocity (NASDAQ:
                            PCTY) is an award-winning provider of cloud-based HR
                            and payroll software solutions. Founded in 1997 and
                            publicly traded since 2014, Paylocity offers an
                            intuitive, easy-to-use product suite that helps
                            businesses automate and streamline HR and payroll
                            processes, attract and retain talent, and build
                            culture and connection with their employees. Known
                            for its unique culture and consistently recognized
                            as one of the best places to work, Paylocity
                            accompanies its clients on the journey to create
                            great workplaces and help all employees achieve
                            their best
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        George Tapling, Inside Sales Franchise Account Executive
                        <br />
                        <a href="mailto:gtapling@paylocity.com">
                            gtapling@paylocity.com
                        </a>
                        <br />
                        <a href="tel:208-616-7060">208-616-7060</a>
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={EmcentrixLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Emcentrix</span>
                        <div className="address">
                            Maintaining your employee data in filing cabinets,
                            spreadsheets, or multiple separate software systems
                            costs you in time and money. Connect your employee
                            information from end to end with EmCentrix, an
                            all-in-one HR software that consolidates your
                            employee data, applicant tracking, onboarding, time
                            clocks, time sheets, time off, benefits, performance
                            management, employee files, and more, all in a
                            single platform that integrates with payroll and POS
                            systems. All of this information is shared and
                            updated throughout the enterprise online, without
                            the need for redundant data entry, streamlining your
                            HR & payroll processes and saving you time and
                            money. EmCentrix can integrate with your existing
                            vendors, and have created a partnership with
                            Altametrics to connect your HR data with your POS,
                            providing an enterprise wide solution that is both
                            powerful and simple.
                        </div>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Sales
                        <br />
                        <a href="tel:(818)-995-2495">(818)-995-2495 ext. 101</a>
                        <a href="mailto:sales@emcentrix.com">
                            sales@emcentrix.com
                        </a>
                    </p>
                </ContactContainer>
            </SectionContainer>
        </AccordionSection>
        {/* Insurance */}
        <AccordionSection title="Insurance">
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={PizzaAgentsLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Pizza Agents</span>
                        <div className="address">
                            <text>
                                <b>
                                    Insurance Types: Property, General
                                    Liability, EPLI, Auto, Work Comp, Umbrella,
                                    Etc.
                                </b>{' '}
                                The team at CS&A Insurance are your{' '}
                                <b>Pizza Agents!</b> We have been part of the{' '}
                                <b>Little Caesars</b> community for{' '}
                                <b>over 20 years</b> and understand the
                                business. Our experience allows us to build
                                genuine, long-term relationships based on trust
                                and communication. We may not always be the
                                lowest price, but we honor our word and are
                                always looking out for our franchisees' best
                                interest.
                            </text>
                        </div>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Travis Wright
                        <br />
                        <a href="tel:615-556-5526">615-556-5526</a>
                        <br />
                        <a href="mailto:travis@pizzaagents.com">
                            travis@pizzaagents.com
                        </a>
                        <br />
                        Luke Marthedal
                        <br />
                        <a href="tel:615-440-9152">615-440-9152</a>
                        <br />
                        <a href="mailto:luke@pizzaagents.com">
                            luke@pizzaagents.com
                        </a>
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={IntrepidLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Intrepid Direct Insurance</span>
                        <div className="address">
                            <text>
                                Intrepid Direct Insurance is a risk management
                                and insurance provider specializing in the
                                restaurant franchise space. Intrepid Direct
                                works directly with Little Caesars franchise
                                owners, rather than through a broker/agent,
                                which can save up to 20% on insurance premiums.
                                There are no additional finance fees, and we
                                also accept credit cards.
                            </text>
                        </div>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <a
                        href="https://intrepiddirect.com/industries/franchise-restaurant-insurance/qsr/little-caesars/?utm_source=iolcf&utm_medium=website&utm_campaign=online_vendor_directory"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Request a quote today!
                    </a>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={USI}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">USI Insurance Services</span>
                        <span className="address">
                            Property, Casualty and Workers Compensation
                            insurance.
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Jim Karrip
                        <br />
                        <a href="mailto:Jim.Karrip@usi.com">
                            Jim.Karrip@usi.com
                        </a>
                    </p>
                </ContactContainer>
            </SectionContainer>
        </AccordionSection>
        {/* Technology */}
        <AccordionSection title="Technology">
            {/* Rockbot */}
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={rockbotLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Rockbot</span>
                        <span className="address">
                            Make sure your stores are Hot-N-Ready with The
                            Little Caesars Media Program, powered by Rockbot -
                            the easiest way to bring your in-store experience to
                            life, drive employee engagement, and market to
                            customers. The program includes fully licensed &
                            curated music built just for Little Caesars, a
                            crazy-deal on TV entertainment, and real-time
                            Digital Signage you can control from anywhere.
                            Choose one or make it a Combo to fit your store's
                            appetite:
                        </span>
                        <div style={{ marginTop: '4px' }}>
                            <div>Little Caesars Radio</div>
                            <div>Little Caesars Digital Signage</div>
                            <div>Little Caesars TV</div>
                        </div>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Elise Kowan
                        <br />
                        Account Executive
                        <br />
                        elise.kowan@rockbot.com
                        <br />
                        <a href="tel:415-651-2653">415-651-2653</a>
                        <br />
                        <a
                            href="https://rockbot.com/littlecaesars"
                            target="_blank"
                            rel="noreferrer"
                        >
                            rockbot.com/littlecaesars
                        </a>
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={Restaurant365}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Restaurant365</span>
                        <span className="address">
                            Restaurant365 is the industry's leading all-in-one,
                            cloud-based accounting, inventory, scheduling,
                            payroll, and HR solution developed specifically for
                            restaurants. R365's restaurant enterprise management
                            software simplifies day-to-day management for
                            operators, allowing them to control food costs and
                            optimize labor. Integrations and open APIs enable
                            Restaurant365 to connect with other systems,
                            including POS providers, vendors, and banks. The
                            result is accurate, timely reporting that provides a
                            clear and complete view of their businesses.
                            Restaurant365 is based in Irvine, California with an
                            office in Austin, Texas. The company is backed by
                            Bessemer Venture Partners, ICONIQ, KKR, L Catterton,
                            and Serent Capital. Additional information is
                            available at restaurant365.com
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Erik Hundevad
                        <br />
                        Account Executive, Enterprise
                        <br />
                        <a href="tel:612-910-5092">612-910-5092</a>
                        <br />
                        <a
                            href="https://www.restaurant365.com/request-demo/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            restaurant365.com
                        </a>
                        <br />
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://meetings.salesloft.com/restaurant365/erikhundevad"
                        >
                            Schedule a quick chat
                        </a>
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={ReachifyLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Reachify</span>
                        <div className="address">
                            <text>
                                "Reachify is restaurant phone technology with
                                options for automations, text messaging, missed
                                call automation, and so much more."
                            </text>
                        </div>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer></ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={UpShowLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Up Show</span>
                        <span className="address">
                            Deliver the best on-premise employee experience with
                            UPshow SHIFT, the only employee engagement platform
                            designed for deskless workers. UPshow SHIFT
                            demonstrably improves employee communication, boosts
                            employee productivity, increases operational
                            excellence and speeds up employee training.
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Scott Axonovitz
                        <br />
                        Chief Business Officer
                        <br />
                        <a href="tel:(419)-261-1802">419-261-1802</a>
                        <br />
                        <a href="mailto:scott@upshow.tv">scott@upshow.tv</a>
                    </p>
                </ContactContainer>
            </SectionContainer>
            <SectionContainer className="section__container">
                <PersonContainer>
                    <ImgContainer>
                        <Img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={OnarollLogo}
                        />
                    </ImgContainer>
                    <DetailContainer>
                        <span className="name">Onaroll</span>
                        <span className="address">
                            Onaroll is a first-of-its-kind celebration,
                            engagement and gamification experience for shift
                            workers, trusted by the nation's largest QSRs to
                            retain team members and drive the metrics they care
                            about the most in-store.
                        </span>
                        <br />
                        <span>
                            <b>For shift workers, </b>
                            we've built a simplified SMS based celebration and
                            gamification experience. We hear it over and over:
                            they feel more loved and celebrated than ever
                            before.
                            <b> For operators, </b>
                            we're driving the metrics that are most important to
                            improving their businesses – from top line sales to
                            retention, productivity, engagement and in-store
                            morale.
                        </span>
                        <br />
                        <span>
                            IOLCF members receive exclusive pricing. Contact
                            Onaroll to learn more.
                        </span>
                    </DetailContainer>
                </PersonContainer>
                <ContactContainer>
                    <h3>Contact</h3>
                    <p>
                        Mac Trowbridge
                        <br />
                        Senior Growth Manager
                        <br />
                        <a href="mailto:mac@onaroll.co">mac@onaroll.co</a>
                    </p>
                </ContactContainer>
            </SectionContainer>
        </AccordionSection>
    </AccordionStyles>
);

export default VendorDirectoryAccordion;
